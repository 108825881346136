import { addDays, areIntervalsOverlapping, format, isValid, parse, subDays } from 'date-fns';
import difference from 'lodash-es/difference';

import { absenceInPeriodOrTimeRange, isAbsenceInPeriodOrTimeRange } from '../../../reducers/orm/absence/absence.helper';
import { AbsenceModel } from '../../../reducers/orm/absence/absence.model';
import { AvailabilityModel, AvailabilityType } from '../../../reducers/orm/availability/availability.model';
import {
  EmployeeModel,
  EmployeeWithContractInfo,
  HasStartAndEndDate,
} from '../../../reducers/orm/employee/employee.model';
import { employeeActiveInPeriod } from '../../../reducers/orm/employee/employee.service';
import { RequiredShiftModel } from '../../../reducers/orm/required-shift/required-shift.model';
import { ScheduleModel } from '../../../reducers/orm/schedule/schedule.model';
import {
  determineEndTimeForComparison,
  HasDateStringAndTime,
  HasTime,
  normalizeTime,
  parseDate,
  timeConflictFilter,
} from '../../../shared/date.helper';
import { hasAtleastSubscriptionPlan } from '../../../shared/subscription-plan/subscription-plan.directive';
import { PlanType, SubscriptionModel } from '../../+reports/shared/subscriptions/subscription.model';
import { OpenShiftModel } from './../../../reducers/orm/open-shift/open-shift.model';
import { ConflictContainer } from './../../../reducers/orm/schedule/schedule.model';
import { ShiftModel } from './../../../reducers/orm/shift/shift.model';
import { SkillModel } from './../../../reducers/orm/skill/skill.model';
import { EmployeeWithScheduleTotals } from './schedule.interfaces';

/**
 * Checks if an employee can be scheduled on a specific date.
 *
 * This function is used to determine if a dropzone can accept
 * the scheduled shift which is being dragged over it.
 *
 * Unfortunately the drag and drop library we use `ng2-dnd`, only
 * accepts a function which returns a boolean instantly. This means
 * that we cannot use Observables.
 *
 * This means that the `employee` and `absences` must be provided to
 * this function, in a non asynchronous manner. This is why the users
 * of `canEmployeeBeScheduledOnDate` go to so much trouble to have
 * subscriptions themselves.
 *
 * Another possibility is to enhance all Schedule types with the Employee
 * and to enhance all Employees with Absences. But I deemed this to be
 * overkill and would make things massively more complex. Also this
 * function is only called when the user is actually dragging over a
 * cell, so it does not occur that often.
 *
 * @param {EmployeeModel | EmployeeWithContractInfo} employee
 * @param {string} date
 * @param {AbsenceModel[]} absences
 * @returns {boolean}
 */
export function canEmployeeBeScheduledOnDate(
  employee: EmployeeModel | EmployeeWithContractInfo | HasStartAndEndDate,
  date: string,
  absences: AbsenceModel[],
): boolean {
  return employeeActiveInPeriod(date, date)(employee);
}

export function dragAndDropHasConflict(
  schedule: ScheduleModel | OpenShiftModel | RequiredShiftModel,
  date: string,
  employee: EmployeeWithScheduleTotals,
  schedules: ScheduleModel[],
  absences: AbsenceModel[],
  availabilities: AvailabilityModel[],
  accountSubscription: SubscriptionModel,
) {
  const scheduleIsTask = (schedule.Shift && schedule.Shift.is_task) || schedule.is_task;

  // Conflicting absences
  const hasConflictingAbsences =
    absences.filter(
      (absence) =>
        absence.user_id === employee.id &&
        absence.status === 'Approved' &&
        isAbsenceInPeriodOrTimeRange(date, schedule.startDateTime, schedule.endDateTime)(absence),
    ).length > 0;

  // Conflicting availabilities
  const hasConflictingAvailabilities =
    !scheduleIsTask &&
    availabilities.filter(
      (availability) =>
        availability.user_id === employee.id &&
        availability.date === date &&
        scheduleHasConflictWithAvailabilityType(schedule, availability),
    ).length > 0;

  // Conflicting schedules
  const scheduleEnd = determineEndTimeForComparison(schedule.starttime, schedule.endtime);
  const previousDay = format(subDays(parseDate(schedule.date), 1), 'yyyy-MM-dd');
  const nextDay = format(addDays(parseDate(schedule.date), 1), 'yyyy-MM-dd');

  const hasConflictingSchedules =
    !scheduleIsTask &&
    schedules.filter((checked: ScheduleModel) => {
      // Ignore tasks
      if (checked.Shift && checked.Shift.is_task) {
        return false;
      }

      // Ignore the schedule which is being checked.
      // Only for scheduled shifts: not for open shifts / required shifts
      if (schedule.user_id !== null) {
        if (schedule.id === checked.id) {
          return false;
        }
      }

      // Only when the users are the same
      if (checked.user_id !== employee.id) {
        return false;
      }

      // Only when the date is also the same
      if (date !== checked.date) {
        return isOverlappingAcrossDay(previousDay, nextDay, schedule, checked);
      }

      const checkedEnd = determineEndTimeForComparison(checked.starttime, checked.endtime);

      return schedule.starttime < checkedEnd && scheduleEnd > checked.starttime;
    }).length > 0;

  const hasMissingSkills = scheduleHasConflictWithSkills(schedule, employee).length > 0;

  const hasAccessToAbsences = hasAtleastSubscriptionPlan(PlanType.BASIC, accountSubscription);
  const hasAccessToAvailabilities = hasAtleastSubscriptionPlan(PlanType.BASIC, accountSubscription);
  const hasAccessToSchedules = hasAtleastSubscriptionPlan(PlanType.BASIC, accountSubscription);
  const hasAccessToSkills = hasAtleastSubscriptionPlan(PlanType.EARLY_ADOPTER, accountSubscription);

  return (
    (hasAccessToAbsences && hasConflictingAbsences) ||
    (hasAccessToAvailabilities && hasConflictingAvailabilities) ||
    (hasAccessToSchedules && hasConflictingSchedules) ||
    (hasAccessToSkills && hasMissingSkills)
  );
}

export function dragAndDropShiftHasConflict(
  shift: ShiftModel,
  date: string,
  employee: EmployeeWithScheduleTotals,
  schedules: ScheduleModel[],
  absences: AbsenceModel[],
  availabilities: AvailabilityModel[],
  accountSubscription: SubscriptionModel,
) {
  if (shift.is_task) {
    return false;
  }

  const startDateTime = parse(date + ' ' + shift.starttime, 'yyyy-MM-dd HH:mm:ss', new Date());
  const endDateTime = parse(date + ' ' + shift.endtime, 'yyyy-MM-dd HH:mm:ss', new Date());

  // Conflicting absences
  const conflictingAbsences = absences.filter(
    (absence) =>
      absence.user_id === employee.id &&
      absence.status === 'Approved' &&
      isAbsenceInPeriodOrTimeRange(date, startDateTime, endDateTime)(absence),
  );

  // Conflicting availabilities
  const conflictingAvailabilities = availabilities.filter(
    (availability) =>
      availability.user_id === employee.id &&
      availability.date === date &&
      scheduleHasConflictWithAvailabilityType(shift, availability),
  );

  // Conflicting schedules
  const scheduleEnd = determineEndTimeForComparison(shift.starttime, shift.endtime);
  const previousDay = format(subDays(parseDate(date), 1), 'yyyy-MM-dd');
  const nextDay = format(addDays(parseDate(date), 1), 'yyyy-MM-dd');

  const conflictingSchedules = schedules.filter((checked: ScheduleModel) => {
    // Ignore tasks
    if (checked.Shift && checked.Shift.is_task) {
      return false;
    }

    // Only when the users are the same
    if (checked.user_id !== employee.id) {
      return false;
    }

    // Only when the date is also the same
    if (date !== checked.date) {
      return isOverlappingAcrossDay(previousDay, nextDay, shift, checked);
    }

    const checkedEnd = determineEndTimeForComparison(checked.starttime, checked.endtime);

    return shift.starttime < checkedEnd && scheduleEnd > checked.starttime;
  });

  const hasAccessToSkills = hasAtleastSubscriptionPlan(PlanType.EARLY_ADOPTER, accountSubscription);
  let conflictingSkills = false;

  if (hasAccessToSkills) {
    const employeeSkillIds = employee.Skill?.map((skill) => skill.id);
    const shiftSkillIds = shift.Skill?.map((skill) => skill.id);
    conflictingSkills = !shiftSkillIds.every((id) => employeeSkillIds.includes(id));
  }

  const hasAccessToAbsences = hasAtleastSubscriptionPlan(PlanType.BASIC, accountSubscription);
  const hasAccessToAvailabilities = hasAtleastSubscriptionPlan(PlanType.BASIC, accountSubscription);
  const hasAccessToSchedules = hasAtleastSubscriptionPlan(PlanType.BASIC, accountSubscription);

  return (
    (hasAccessToAbsences && conflictingAbsences.length > 0) ||
    (hasAccessToAvailabilities && conflictingAvailabilities.length > 0) ||
    (hasAccessToSchedules && conflictingSchedules.length > 0) ||
    (hasAccessToSkills && conflictingSkills)
  );
}

export function availabilityHasSchedule(availability: AvailabilityModel, schedules: ScheduleModel[]) {
  const previousDay = format(subDays(parseDate(availability.date), 1), 'yyyy-MM-dd');
  const nextDay = format(addDays(parseDate(availability.date), 1), 'yyyy-MM-dd');

  return schedules.some((schedule) => {
    if (availability.date !== schedule.date) {
      return isOverlappingAcrossDay(previousDay, nextDay, availability, schedule);
    }

    if (
      availability.type === AvailabilityType.AVAILABLE_ALL_DAY ||
      availability.type === AvailabilityType.UNAVAILABLE_ALL_DAY
    ) {
      return true;
    } else {
      return timeConflictFilter(availability)(schedule);
    }
  });
}

export function scheduleHasConflictWithSchedules(schedule: ScheduleModel, schedules: ScheduleModel[]): ScheduleModel[] {
  if (schedule.Shift && schedule.Shift.is_task) {
    return [];
  }

  const scheduleEnd = normalizeTime(determineEndTimeForComparison(schedule.starttime, schedule.endtime));

  const previousDay = format(subDays(parseDate(schedule.date), 1), 'yyyy-MM-dd');
  const nextDay = format(addDays(parseDate(schedule.date), 1), 'yyyy-MM-dd');

  return schedules.filter((checked: ScheduleModel) => {
    // Ignore tasks
    if (checked.Shift && checked.Shift.is_task) {
      return false;
    }

    // Ignore the schedule which is being checked.
    if (schedule.id === checked.id) {
      return false;
    }

    // Only when the users are the same
    if (schedule.user_id !== checked.user_id) {
      return false;
    }

    // Only when the date is also the same
    if (schedule.date !== checked.date) {
      return isOverlappingAcrossDay(previousDay, nextDay, schedule, checked);
    }

    const checkedEnd = normalizeTime(determineEndTimeForComparison(checked.starttime, checked.endtime));

    const scheduleStart = normalizeTime(schedule.starttime);
    const checkedStart = normalizeTime(checked.starttime);

    return scheduleStart < checkedEnd && scheduleEnd > checkedStart;
  });
}

export function isOverlappingAcrossDay(
  previousDay: string,
  nextDay: string,
  mainObject: HasTime,
  otherObject: HasDateStringAndTime,
) {
  if (otherObject.date === previousDay && otherObject.starttime > otherObject.endtime) {
    return mainObject.starttime < otherObject.endtime;
  }
  if (otherObject.date === nextDay && mainObject.starttime > mainObject.endtime) {
    return otherObject.starttime < mainObject.endtime;
  }

  return false;
}

export function scheduleHasConflictWithAbsences(
  schedule: ScheduleModel | OpenShiftModel | RequiredShiftModel,
  absences: AbsenceModel[],
): AbsenceModel[] {
  if (schedule.Shift && schedule.Shift.is_task) {
    return [];
  }

  const isAbsenceInPeriod = absenceInPeriodOrTimeRange(schedule);

  return absences.filter(
    (absence) => absence.user_id === schedule.user_id && absence.status === 'Approved' && isAbsenceInPeriod(absence),
  );
}

export function scheduleMatchesWithAvailabilities(
  schedule: ScheduleModel,
  availabilities: AvailabilityModel[],
): AvailabilityModel[] {
  return availabilities.filter(
    (availability) =>
      availability.user_id === schedule.user_id &&
      availability.date === schedule.date &&
      scheduleMatchesWithAvailabilityType(schedule, availability),
  );
}

// Checks conflict based on type, date and user have already been checked.
function scheduleMatchesWithAvailabilityType(
  schedule: ScheduleModel | OpenShiftModel | RequiredShiftModel | ShiftModel,
  availability: AvailabilityModel,
): boolean {
  switch (availability.type) {
    case 'Unavailable from': {
      return !scheduleHasConflictWithAvailabilityTypeUnavailableFrom(schedule, availability);
    }

    case 'Available from': {
      return !scheduleHasConflictWithAvailabilityTypeAvailableFrom(schedule, availability);
    }

    case 'Available all day':
      return true; // Available all day, which is never in conflict with the schedule.

    case 'Unavailable all day': {
      return false; // Unavailable all day, which is always in conflict with the schedule.
    }
  }
}

export function scheduleHasConflictWithAvailabilities(
  schedule: ScheduleModel,
  availabilities: AvailabilityModel[],
): AvailabilityModel[] {
  if (schedule.Shift?.is_task) {
    return [];
  }

  if (!isValid(schedule.startDateTime) || !isValid(schedule.endDateTime)) {
    return [];
  }

  return availabilities.filter((availability: AvailabilityModel) => {
    if (availability.user_id !== schedule.user_id) {
      return false;
    }

    if (availability.date !== schedule.date) {
      return false;
    }

    if (!isValid(availability.startDateTime) || !isValid(availability.endDateTime)) {
      return false;
    }

    return scheduleOverlapForAvailabilityType(schedule, availability);
  });
}

const scheduleOverlapForAvailabilityType = (schedule: ScheduleModel, availability: AvailabilityModel) => {
  const overlap = areIntervalsOverlapping(
    { start: schedule.startDateTime, end: schedule.endDateTime },
    { start: availability.startDateTime, end: availability.endDateTime },
  );
  switch (availability.type) {
    case AvailabilityType.UNAVAILABLE_FROM: {
      return overlap;
    }

    case AvailabilityType.AVAILABLE_FROM: {
      return !overlap;
    }

    case AvailabilityType.AVAILABLE_ALL_DAY:
      return false; // Available all day, which is never in conflict with the schedule.

    case AvailabilityType.UNAVAILABLE_ALL_DAY: {
      return true; // Unavailable all day, which is always in conflict with the schedule.
    }
  }
};

// Checks conflict based on type, date and user have already been checked.
function scheduleHasConflictWithAvailabilityType(
  schedule: ScheduleModel | OpenShiftModel | RequiredShiftModel | ShiftModel,
  availability: AvailabilityModel,
): boolean {
  switch (availability.type) {
    case 'Unavailable from': {
      return scheduleHasConflictWithAvailabilityTypeUnavailableFrom(schedule, availability);
    }

    case 'Available from': {
      return scheduleHasConflictWithAvailabilityTypeAvailableFrom(schedule, availability);
    }

    case 'Available all day':
      return false; // Available all day, which is never in conflict with the schedule.

    case 'Unavailable all day': {
      return true; // Unavailable all day, which is always in conflict with the schedule.
    }
  }
}

/**
 * Checks if the schedule is in conflict with a availability of type 'Unavailable from'.
 *
 *  All possible Scenarios:
 *
 *     Where the brackets denote the availability and the parenthesis the schedule:
 *
 *  A   [  ()        ]  = Conflicted because the user is not available when scheduled at all.
 *
 *  B   [    ]      ()  = Fine because the user is available when scheduled after the availability.
 *
 *  C   () [         ]  = Fine because the user is available when schedule before the availability.
 *
 *  D   ( [ )        ]  = Conflict because the user becomes unavailable before the schedule ends.
 *
 *  E   [        ( ] )  = Conflict because the user is unavailable when the schedule started.
 *
 * Note: this function expects that the schedule's date and the
 * availabilities date are the same.
 *
 * @param {ScheduleModel} schedule
 * @param {AvailabilityModel} availability
 * @returns {boolean}
 */
function scheduleHasConflictWithAvailabilityTypeUnavailableFrom(
  schedule: ScheduleModel | OpenShiftModel | RequiredShiftModel | ShiftModel,
  availability: AvailabilityModel,
): boolean {
  const scheduleEnd = determineEndTimeForComparison(schedule.starttime, schedule.endtime);

  const availabilityEnd = determineEndTimeForComparison(availability.starttime, availability.endtime);

  return schedule.starttime < availabilityEnd && scheduleEnd > availability.starttime;
}

/**
 * Checks if the schedule is in conflict with a availability of type 'AvailableFrom'.
 *
 *  All possible Scenarios:
 *
 *     Where the brackets denote the availability and the parenthesis the schedule:
 *
 *  A   [  ()        ]  = Fine because the user is completely available when scheduled.
 *
 *  B   [    ]      ()  = Conflict the user is not available after the availability.
 *
 *  C   () [         ]  = Conflict the user is not available before the availability.
 *
 *  D   ( [ )        ]  = Conflict because the user is unavailable before the schedule starts.
 *
 *  E   [        ( ] )  = Conflict because the user is unavailable when the schedule ends.
 *
 * Note: this function expects that the schedule's date and the
 * availabilities date are the same.
 *
 * @param {ScheduleModel} schedule
 * @param {AvailabilityModel} availability
 * @returns {boolean}
 */
function scheduleHasConflictWithAvailabilityTypeAvailableFrom(
  schedule: ScheduleModel | OpenShiftModel | RequiredShiftModel | ShiftModel,
  availability: AvailabilityModel,
): boolean {
  const scheduleEnd = determineEndTimeForComparison(schedule.starttime, schedule.endtime);

  const availabilityEnd = determineEndTimeForComparison(availability.starttime, availability.endtime);

  return schedule.starttime < availability.starttime || scheduleEnd > availabilityEnd;
}

// Checks conflicts between skills associated withs shift and skills associated with employee
export function scheduleHasConflictWithSkills(
  schedule: ScheduleModel | OpenShiftModel | RequiredShiftModel,
  employee: EmployeeModel | EmployeeWithContractInfo,
): SkillModel[] {
  const shiftSkillsExist = schedule && schedule.Shift && schedule.Shift.Skill;
  const employeeSkillsExist = employee && employee.Skill;

  if (!shiftSkillsExist || !employeeSkillsExist || schedule.Shift.Skill.length === 0) {
    return [];
  }

  const shiftSkillIds = schedule.Shift?.Skill?.map((skill) => skill.id);
  const employeeSkillIds = employee.Skill?.map((skill) => skill.id);

  const diff = difference(shiftSkillIds ?? [], employeeSkillIds ?? []);

  return schedule.Shift.Skill.filter((skill) => diff.indexOf(skill.id) !== -1);
}
