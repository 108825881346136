import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, catchError, Observable, tap, throwError } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppState } from '../../index';
import { TimesheetConflictAction } from './timesheet-conflict.action';
import { TimesheetConflictApi } from './timesheet-conflict.api';
import {
  TimesheetConflictCheckRequest,
  TimesheetConflictModel,
  TimesheetConflictRequest,
  TimesheetConflictResponse,
  TimesheetConflictStoreModel,
} from './timesheet-conflict.model';

@Injectable()
export class TimesheetConflictService {
  public previousLoadRequest = new BehaviorSubject<TimesheetConflictRequest>(null);
  public constructor(
    private api: TimesheetConflictApi,
    private store: Store<AppState>,
  ) {}

  public checkConflicts(request: TimesheetConflictCheckRequest): Observable<TimesheetConflictModel[]> {
    if (!request.excludeTimesheetId) {
      delete request.excludeTimesheetId;
    }

    return this.api.conflictCheck(request);
  }

  public loadConflicts(request: TimesheetConflictRequest) {
    this.previousLoadRequest.next(request);
    this.store.dispatch(TimesheetConflictAction.load());
    return this.api.loadConflicts(request).pipe(
      map((response: TimesheetConflictResponse[]) => {
        const conflicts: TimesheetConflictStoreModel[] = response?.map(({ conflicts, timesheetId }) => ({
          id: timesheetId,
          conflicts,
          timestamp: new Date().getTime(),
        }));

        return conflicts as TimesheetConflictStoreModel[];
      }),
      tap((model) => {
        this.store.dispatch(TimesheetConflictAction.loadSuccess(model));
      }),
      catchError((err) => {
        this.store.dispatch(TimesheetConflictAction.loadFailed(err));
        return throwError(err);
      }),
    );
  }
}
