import { UnsafeAction as Action } from '../../interfaces';
import { TimesheetConflictStoreModel } from './timesheet-conflict.model';

const ACTION_PREFIX = '[TimesheetConflicts]';

export const timesheetConflictActionType = {
  RESET: `${ACTION_PREFIX} Reset`,
  LOAD: `${ACTION_PREFIX} Load`,
  LOAD_SUCCESS: `${ACTION_PREFIX} Load Success`,
  LOAD_FAILED: `${ACTION_PREFIX} Load Failed`,
};

export class TimesheetConflictAction {
  static reset(): Action {
    return {
      type: timesheetConflictActionType.RESET,
    };
  }

  static load(): Action {
    return {
      type: timesheetConflictActionType.LOAD,
    };
  }

  static loadSuccess(timesheetConflicts: TimesheetConflictStoreModel[]): Action {
    return {
      type: timesheetConflictActionType.LOAD_SUCCESS,
      payload: timesheetConflicts,
    };
  }

  static loadFailed(err): Action {
    return {
      type: timesheetConflictActionType.LOAD_FAILED,
      payload: err,
    };
  }
}
