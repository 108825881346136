import fastDeepEqual from 'fast-deep-equal';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, tap, throttleTime } from 'rxjs/operators';

/**
 * Observable which is aggressively lazy in letting the output through.
 *
 * `lazySelect` is a lazy variant of `ngrx-store`'s `select` operator.
 *
 * The difference lies in two things:
 *
 *   1. lazySelect throttles the throughput. The throttling works as follows:
 *      The first event in a series is always allowed to pass, subsequent
 *      events in a series are throttled by 200 milliseconds.
 *
 *      In a Marble diagram:
 *
 *      Input:  '-a-xy-----b--x--cxxx--|'
 *      Output: '-a---y----b---x-c---x-|'
 *
 *      The first event in a series is always emitted so the user does not look
 *      at a loading screen needlessly.
 *
 *   2. lazySelect does a deep equality check, instead of a shallow equality check
 *      when deciding if the observable should be filtered. This way UI components
 *      only re-render when the data actually changes.
 *
 * @param {(state: T) => any} selector A selector which transforms the state.
 * @param {number} options.duration The duration time, defaults to 200 milliseconds.
 *
 * @returns {Observable<O>} An observable which outputs whatever the selector returns.
 */
export const lazySelect =
  <T, O>(selector: (state: T) => O, options: { duration: number } = { duration: 200 }) =>
  (source: Observable<T>): Observable<O> =>
    Observable.create((subscriber) =>
      source
        .pipe(
          /*
        Throttle the events from the observable.
        If changes in the store happen rapidly wait a certain
        amount of time before applying the `selector`. This
        way we wait for the update storm to pass before doing
        the expensive `selector` operations.

        Leading is true, so the first event in a series of events
        is always emitted, trailing is true so only the last event
        of a series is emitted. This way the user does not look at
        a loading state very long.
      */
          throttleTime(options.duration, undefined, { leading: true, trailing: true }),
          /*
        Actually perform the `selector` we assume that the selector
        is quite heavy. That is why we throttle before doing the selector
        rather than after performing the selector.
        */
          map(selector),
          /*
        We only send the output of a `selector` through when the
        output of the selector has changed. We check on the actual
        object's value instead of doing a reference check here by using
        `fast-deep-equal`.

        The reason we do that is because the `selector` frequently
        returns the exact same value.
        */
          distinctUntilChanged(fastDeepEqual),
        )
        .subscribe(
          (data) => subscriber.next(data),
          (err) => subscriber.error(err),
          () => subscriber.complete(),
        ),
    );

export const debugSelect =
  <T, O>(selector: (state: T) => O, name: string) =>
  (source: Observable<T>): Observable<O> =>
    Observable.create((subscriber) =>
      source
        .pipe(
          throttleTime(200, undefined, { leading: true, trailing: true }),
          tap(() => {
            console.time(name);
          }),
          map(selector),
          tap(() => {
            console.timeEnd(name);
          }),
          distinctUntilChanged(fastDeepEqual),
        )
        .subscribe(
          (data) => subscriber.next(data),
          (err) => subscriber.error(err),
          () => subscriber.complete(),
        ),
    );
