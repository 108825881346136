import { Injectable } from '@angular/core';
import { ArrayStrategy, objectToSearchParams } from '@app/api/api.helper';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiGateway } from '../../../api/ApiGateway.service';
import {
  TimesheetConflictCheckRequest,
  TimesheetConflictModel,
  TimesheetConflictRequest,
  TimesheetConflictResponse,
} from './timesheet-conflict.model';

@Injectable()
export class TimesheetConflictApi {
  private endpoint = 'timesheets/conflicts';

  public constructor(private gateway: ApiGateway) {}

  public conflictCheck(request: TimesheetConflictCheckRequest): Observable<TimesheetConflictModel[]> {
    return this.gateway.get(this.endpoint + '/check', { params: request }).pipe(map((response) => response));
  }

  public loadConflicts(request: TimesheetConflictRequest): Observable<TimesheetConflictResponse[]> {
    const queryParams = objectToSearchParams(request, ArrayStrategy.MULTIPLE_PARAMS);

    return this.gateway.get(this.endpoint, { params: queryParams }) as Observable<TimesheetConflictResponse[]>;
  }
}
