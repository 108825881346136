import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { SharedModelState } from '../shared.model.state';

export interface AvailabilityState extends SharedModelState<AvailabilityModel> {}

export interface AvailabilityMap {
  [id: string]: AvailabilityModel;
}

export interface AvailabilityRequiredData {
  user_id: string;
  date: string;
  [field: string]: any;
}

export enum AvailabilityType {
  AVAILABLE_ALL_DAY = 'Available all day',
  AVAILABLE_FROM = 'Available from',
  UNAVAILABLE_ALL_DAY = 'Unavailable all day',
  UNAVAILABLE_FROM = 'Unavailable from',
}

export interface AvailabilityModel {
  id: string;
  account_id: string;
  user_id: string;
  date: string;
  all_day: boolean;
  type: AvailabilityType | null;
  starttime: string;
  endtime: string;
  reason: string;
  created: string;
  modified: string;
  created_by: string;
  modified_by: string;
  startDateTime?: Date;
  endDateTime?: Date;
  color?: string;
  color_is_dark?: boolean;
  color_rgb?: string;
  canEdit?: boolean;
  [propName: string]: any; // allow for custom properties
}

// Only for translations
const translations = [_('Available all day'), _('Available from'), _('Unavailable all day'), _('Unavailable from')];
